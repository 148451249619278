/**
 * Converts a given value to a percentage string.
 *
 * @param {Number} $value - The value to be converted to a percentage.
 * @return {String} - The percentage representation of the value.
 */
/**
 * Generates a transparent version of the given color.
 *
 * @param {Color} $color - The base color to be made transparent.
 * @param {Number} $alpha - The level of transparency, ranging from 0 (fully transparent) to 1 (fully opaque). Default is 1.
 * @return {Color} - The resulting color with the specified transparency.
 */
.payment {
  height: 2.5rem;
  aspect-ratio: 1.66666;
  display: inline-block;
  background: no-repeat center/100% 100%;
  vertical-align: bottom;
  font-style: normal;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.payment-provider-2c2p {
  background-image: url("../img/payments/2c2p.svg");
}

.payment-provider-2c2p-dark {
  background-image: url("../img/payments/2c2p-dark.svg");
}

.payment-provider-2checkout {
  background-image: url("../img/payments/2checkout.svg");
}

.payment-provider-2checkout-dark {
  background-image: url("../img/payments/2checkout-dark.svg");
}

.payment-provider-adyen {
  background-image: url("../img/payments/adyen.svg");
}

.payment-provider-adyen-dark {
  background-image: url("../img/payments/adyen-dark.svg");
}

.payment-provider-affirm {
  background-image: url("../img/payments/affirm.svg");
}

.payment-provider-affirm-dark {
  background-image: url("../img/payments/affirm-dark.svg");
}

.payment-provider-alipay-plus {
  background-image: url("../img/payments/alipay-plus.svg");
}

.payment-provider-alipay-plus-dark {
  background-image: url("../img/payments/alipay-plus-dark.svg");
}

.payment-provider-alipay {
  background-image: url("../img/payments/alipay.svg");
}

.payment-provider-alipay-dark {
  background-image: url("../img/payments/alipay-dark.svg");
}

.payment-provider-allegro-pay {
  background-image: url("../img/payments/allegro-pay.svg");
}

.payment-provider-allegro-pay-dark {
  background-image: url("../img/payments/allegro-pay-dark.svg");
}

.payment-provider-amazon-pay {
  background-image: url("../img/payments/amazon-pay.svg");
}

.payment-provider-amazon-pay-dark {
  background-image: url("../img/payments/amazon-pay-dark.svg");
}

.payment-provider-amazon {
  background-image: url("../img/payments/amazon.svg");
}

.payment-provider-amazon-dark {
  background-image: url("../img/payments/amazon-dark.svg");
}

.payment-provider-americanexpress {
  background-image: url("../img/payments/americanexpress.svg");
}

.payment-provider-americanexpress-dark {
  background-image: url("../img/payments/americanexpress-dark.svg");
}

.payment-provider-applepay {
  background-image: url("../img/payments/applepay.svg");
}

.payment-provider-applepay-dark {
  background-image: url("../img/payments/applepay-dark.svg");
}

.payment-provider-authorize {
  background-image: url("../img/payments/authorize.svg");
}

.payment-provider-authorize-dark {
  background-image: url("../img/payments/authorize-dark.svg");
}

.payment-provider-autopay {
  background-image: url("../img/payments/autopay.svg");
}

.payment-provider-autopay-dark {
  background-image: url("../img/payments/autopay-dark.svg");
}

.payment-provider-bancontact {
  background-image: url("../img/payments/bancontact.svg");
}

.payment-provider-bancontact-dark {
  background-image: url("../img/payments/bancontact-dark.svg");
}

.payment-provider-binance-usd {
  background-image: url("../img/payments/binance-usd.svg");
}

.payment-provider-binance-usd-dark {
  background-image: url("../img/payments/binance-usd-dark.svg");
}

.payment-provider-bitcoin {
  background-image: url("../img/payments/bitcoin.svg");
}

.payment-provider-bitcoin-dark {
  background-image: url("../img/payments/bitcoin-dark.svg");
}

.payment-provider-bitpay {
  background-image: url("../img/payments/bitpay.svg");
}

.payment-provider-bitpay-dark {
  background-image: url("../img/payments/bitpay-dark.svg");
}

.payment-provider-bkash {
  background-image: url("../img/payments/bkash.svg");
}

.payment-provider-bkash-dark {
  background-image: url("../img/payments/bkash-dark.svg");
}

.payment-provider-blik {
  background-image: url("../img/payments/blik.svg");
}

.payment-provider-blik-dark {
  background-image: url("../img/payments/blik-dark.svg");
}

.payment-provider-braintree {
  background-image: url("../img/payments/braintree.svg");
}

.payment-provider-braintree-dark {
  background-image: url("../img/payments/braintree-dark.svg");
}

.payment-provider-cash-app {
  background-image: url("../img/payments/cash-app.svg");
}

.payment-provider-cash-app-dark {
  background-image: url("../img/payments/cash-app-dark.svg");
}

.payment-provider-chime {
  background-image: url("../img/payments/chime.svg");
}

.payment-provider-chime-dark {
  background-image: url("../img/payments/chime-dark.svg");
}

.payment-provider-cirrus {
  background-image: url("../img/payments/cirrus.svg");
}

.payment-provider-cirrus-dark {
  background-image: url("../img/payments/cirrus-dark.svg");
}

.payment-provider-clickandbuy {
  background-image: url("../img/payments/clickandbuy.svg");
}

.payment-provider-clickandbuy-dark {
  background-image: url("../img/payments/clickandbuy-dark.svg");
}

.payment-provider-coinkite {
  background-image: url("../img/payments/coinkite.svg");
}

.payment-provider-coinkite-dark {
  background-image: url("../img/payments/coinkite-dark.svg");
}

.payment-provider-dinersclub {
  background-image: url("../img/payments/dinersclub.svg");
}

.payment-provider-dinersclub-dark {
  background-image: url("../img/payments/dinersclub-dark.svg");
}

.payment-provider-directdebit {
  background-image: url("../img/payments/directdebit.svg");
}

.payment-provider-directdebit-dark {
  background-image: url("../img/payments/directdebit-dark.svg");
}

.payment-provider-discover {
  background-image: url("../img/payments/discover.svg");
}

.payment-provider-discover-dark {
  background-image: url("../img/payments/discover-dark.svg");
}

.payment-provider-dotpay {
  background-image: url("../img/payments/dotpay.svg");
}

.payment-provider-dotpay-dark {
  background-image: url("../img/payments/dotpay-dark.svg");
}

.payment-provider-dwolla {
  background-image: url("../img/payments/dwolla.svg");
}

.payment-provider-dwolla-dark {
  background-image: url("../img/payments/dwolla-dark.svg");
}

.payment-provider-easypaisa {
  background-image: url("../img/payments/easypaisa.svg");
}

.payment-provider-easypaisa-dark {
  background-image: url("../img/payments/easypaisa-dark.svg");
}

.payment-provider-ebay {
  background-image: url("../img/payments/ebay.svg");
}

.payment-provider-ebay-dark {
  background-image: url("../img/payments/ebay-dark.svg");
}

.payment-provider-elo {
  background-image: url("../img/payments/elo.svg");
}

.payment-provider-elo-dark {
  background-image: url("../img/payments/elo-dark.svg");
}

.payment-provider-epayco {
  background-image: url("../img/payments/epayco.svg");
}

.payment-provider-epayco-dark {
  background-image: url("../img/payments/epayco-dark.svg");
}

.payment-provider-esewa {
  background-image: url("../img/payments/esewa.svg");
}

.payment-provider-esewa-dark {
  background-image: url("../img/payments/esewa-dark.svg");
}

.payment-provider-ethereum {
  background-image: url("../img/payments/ethereum.svg");
}

.payment-provider-ethereum-dark {
  background-image: url("../img/payments/ethereum-dark.svg");
}

.payment-provider-eway {
  background-image: url("../img/payments/eway.svg");
}

.payment-provider-eway-dark {
  background-image: url("../img/payments/eway-dark.svg");
}

.payment-provider-fonepay {
  background-image: url("../img/payments/fonepay.svg");
}

.payment-provider-fonepay-dark {
  background-image: url("../img/payments/fonepay-dark.svg");
}

.payment-provider-giropay {
  background-image: url("../img/payments/giropay.svg");
}

.payment-provider-giropay-dark {
  background-image: url("../img/payments/giropay-dark.svg");
}

.payment-provider-google-pay {
  background-image: url("../img/payments/google-pay.svg");
}

.payment-provider-google-pay-dark {
  background-image: url("../img/payments/google-pay-dark.svg");
}

.payment-provider-googlewallet {
  background-image: url("../img/payments/googlewallet.svg");
}

.payment-provider-googlewallet-dark {
  background-image: url("../img/payments/googlewallet-dark.svg");
}

.payment-provider-hubspot {
  background-image: url("../img/payments/hubspot.svg");
}

.payment-provider-hubspot-dark {
  background-image: url("../img/payments/hubspot-dark.svg");
}

.payment-provider-ingenico {
  background-image: url("../img/payments/ingenico.svg");
}

.payment-provider-ingenico-dark {
  background-image: url("../img/payments/ingenico-dark.svg");
}

.payment-provider-ideal {
  background-image: url("../img/payments/ideal.svg");
}

.payment-provider-ideal-dark {
  background-image: url("../img/payments/ideal-dark.svg");
}

.payment-provider-imepay {
  background-image: url("../img/payments/imepay.svg");
}

.payment-provider-imepay-dark {
  background-image: url("../img/payments/imepay-dark.svg");
}

.payment-provider-jcb {
  background-image: url("../img/payments/jcb.svg");
}

.payment-provider-jcb-dark {
  background-image: url("../img/payments/jcb-dark.svg");
}

.payment-provider-khalti {
  background-image: url("../img/payments/khalti.svg");
}

.payment-provider-khalti-dark {
  background-image: url("../img/payments/khalti-dark.svg");
}

.payment-provider-klarna {
  background-image: url("../img/payments/klarna.svg");
}

.payment-provider-klarna-dark {
  background-image: url("../img/payments/klarna-dark.svg");
}

.payment-provider-laser {
  background-image: url("../img/payments/laser.svg");
}

.payment-provider-laser-dark {
  background-image: url("../img/payments/laser-dark.svg");
}

.payment-provider-litecoin {
  background-image: url("../img/payments/litecoin.svg");
}

.payment-provider-litecoin-dark {
  background-image: url("../img/payments/litecoin-dark.svg");
}

.payment-provider-maestro {
  background-image: url("../img/payments/maestro.svg");
}

.payment-provider-maestro-dark {
  background-image: url("../img/payments/maestro-dark.svg");
}

.payment-provider-mastercard {
  background-image: url("../img/payments/mastercard.svg");
}

.payment-provider-mastercard-dark {
  background-image: url("../img/payments/mastercard-dark.svg");
}

.payment-provider-mercado-pago {
  background-image: url("../img/payments/mercado-pago.svg");
}

.payment-provider-mercado-pago-dark {
  background-image: url("../img/payments/mercado-pago-dark.svg");
}

.payment-provider-metamask {
  background-image: url("../img/payments/metamask.svg");
}

.payment-provider-metamask-dark {
  background-image: url("../img/payments/metamask-dark.svg");
}

.payment-provider-mir {
  background-image: url("../img/payments/mir.svg");
}

.payment-provider-mir-dark {
  background-image: url("../img/payments/mir-dark.svg");
}

.payment-provider-monero {
  background-image: url("../img/payments/monero.svg");
}

.payment-provider-monero-dark {
  background-image: url("../img/payments/monero-dark.svg");
}

.payment-provider-moneygram {
  background-image: url("../img/payments/moneygram.svg");
}

.payment-provider-moneygram-dark {
  background-image: url("../img/payments/moneygram-dark.svg");
}

.payment-provider-neteller {
  background-image: url("../img/payments/neteller.svg");
}

.payment-provider-neteller-dark {
  background-image: url("../img/payments/neteller-dark.svg");
}

.payment-provider-ogone {
  background-image: url("../img/payments/ogone.svg");
}

.payment-provider-ogone-dark {
  background-image: url("../img/payments/ogone-dark.svg");
}

.payment-provider-okpay {
  background-image: url("../img/payments/okpay.svg");
}

.payment-provider-okpay-dark {
  background-image: url("../img/payments/okpay-dark.svg");
}

.payment-provider-opensea {
  background-image: url("../img/payments/opensea.svg");
}

.payment-provider-opensea-dark {
  background-image: url("../img/payments/opensea-dark.svg");
}

.payment-provider-paybox {
  background-image: url("../img/payments/paybox.svg");
}

.payment-provider-paybox-dark {
  background-image: url("../img/payments/paybox-dark.svg");
}

.payment-provider-payconiq {
  background-image: url("../img/payments/payconiq.svg");
}

.payment-provider-payconiq-dark {
  background-image: url("../img/payments/payconiq-dark.svg");
}

.payment-provider-payka {
  background-image: url("../img/payments/payka.svg");
}

.payment-provider-payka-dark {
  background-image: url("../img/payments/payka-dark.svg");
}

.payment-provider-payline {
  background-image: url("../img/payments/payline.svg");
}

.payment-provider-payline-dark {
  background-image: url("../img/payments/payline-dark.svg");
}

.payment-provider-paymill {
  background-image: url("../img/payments/paymill.svg");
}

.payment-provider-paymill-dark {
  background-image: url("../img/payments/paymill-dark.svg");
}

.payment-provider-payone {
  background-image: url("../img/payments/payone.svg");
}

.payment-provider-payone-dark {
  background-image: url("../img/payments/payone-dark.svg");
}

.payment-provider-payoneer {
  background-image: url("../img/payments/payoneer.svg");
}

.payment-provider-payoneer-dark {
  background-image: url("../img/payments/payoneer-dark.svg");
}

.payment-provider-paypal {
  background-image: url("../img/payments/paypal.svg");
}

.payment-provider-paypal-dark {
  background-image: url("../img/payments/paypal-dark.svg");
}

.payment-provider-paypo {
  background-image: url("../img/payments/paypo.svg");
}

.payment-provider-paypo-dark {
  background-image: url("../img/payments/paypo-dark.svg");
}

.payment-provider-paysafe {
  background-image: url("../img/payments/paysafe.svg");
}

.payment-provider-paysafe-dark {
  background-image: url("../img/payments/paysafe-dark.svg");
}

.payment-provider-paysafecard {
  background-image: url("../img/payments/paysafecard.svg");
}

.payment-provider-paysafecard-dark {
  background-image: url("../img/payments/paysafecard-dark.svg");
}

.payment-provider-payu {
  background-image: url("../img/payments/payu.svg");
}

.payment-provider-payu-dark {
  background-image: url("../img/payments/payu-dark.svg");
}

.payment-provider-payza {
  background-image: url("../img/payments/payza.svg");
}

.payment-provider-payza-dark {
  background-image: url("../img/payments/payza-dark.svg");
}

.payment-provider-poli {
  background-image: url("../img/payments/poli.svg");
}

.payment-provider-poli-dark {
  background-image: url("../img/payments/poli-dark.svg");
}

.payment-provider-przelewy24 {
  background-image: url("../img/payments/przelewy24.svg");
}

.payment-provider-przelewy24-dark {
  background-image: url("../img/payments/przelewy24-dark.svg");
}

.payment-provider-revolut-pay {
  background-image: url("../img/payments/revolut-pay.svg");
}

.payment-provider-revolut-pay-dark {
  background-image: url("../img/payments/revolut-pay-dark.svg");
}

.payment-provider-ripple {
  background-image: url("../img/payments/ripple.svg");
}

.payment-provider-ripple-dark {
  background-image: url("../img/payments/ripple-dark.svg");
}

.payment-provider-sage {
  background-image: url("../img/payments/sage.svg");
}

.payment-provider-sage-dark {
  background-image: url("../img/payments/sage-dark.svg");
}

.payment-provider-samsung-pay {
  background-image: url("../img/payments/samsung-pay.svg");
}

.payment-provider-samsung-pay-dark {
  background-image: url("../img/payments/samsung-pay-dark.svg");
}

.payment-provider-sepa {
  background-image: url("../img/payments/sepa.svg");
}

.payment-provider-sepa-dark {
  background-image: url("../img/payments/sepa-dark.svg");
}

.payment-provider-shop-pay {
  background-image: url("../img/payments/shop-pay.svg");
}

.payment-provider-shop-pay-dark {
  background-image: url("../img/payments/shop-pay-dark.svg");
}

.payment-provider-shopify {
  background-image: url("../img/payments/shopify.svg");
}

.payment-provider-shopify-dark {
  background-image: url("../img/payments/shopify-dark.svg");
}

.payment-provider-skrill {
  background-image: url("../img/payments/skrill.svg");
}

.payment-provider-skrill-dark {
  background-image: url("../img/payments/skrill-dark.svg");
}

.payment-provider-solana {
  background-image: url("../img/payments/solana.svg");
}

.payment-provider-solana-dark {
  background-image: url("../img/payments/solana-dark.svg");
}

.payment-provider-solo {
  background-image: url("../img/payments/solo.svg");
}

.payment-provider-solo-dark {
  background-image: url("../img/payments/solo-dark.svg");
}

.payment-provider-spingo {
  background-image: url("../img/payments/spingo.svg");
}

.payment-provider-spingo-dark {
  background-image: url("../img/payments/spingo-dark.svg");
}

.payment-provider-square {
  background-image: url("../img/payments/square.svg");
}

.payment-provider-square-dark {
  background-image: url("../img/payments/square-dark.svg");
}

.payment-provider-stax {
  background-image: url("../img/payments/stax.svg");
}

.payment-provider-stax-dark {
  background-image: url("../img/payments/stax-dark.svg");
}

.payment-provider-stripe {
  background-image: url("../img/payments/stripe.svg");
}

.payment-provider-stripe-dark {
  background-image: url("../img/payments/stripe-dark.svg");
}

.payment-provider-switch {
  background-image: url("../img/payments/switch.svg");
}

.payment-provider-switch-dark {
  background-image: url("../img/payments/switch-dark.svg");
}

.payment-provider-tether {
  background-image: url("../img/payments/tether.svg");
}

.payment-provider-tether-dark {
  background-image: url("../img/payments/tether-dark.svg");
}

.payment-provider-tpay {
  background-image: url("../img/payments/tpay.svg");
}

.payment-provider-tpay-dark {
  background-image: url("../img/payments/tpay-dark.svg");
}

.payment-provider-troy {
  background-image: url("../img/payments/troy.svg");
}

.payment-provider-troy-dark {
  background-image: url("../img/payments/troy-dark.svg");
}

.payment-provider-true-usd {
  background-image: url("../img/payments/true-usd.svg");
}

.payment-provider-true-usd-dark {
  background-image: url("../img/payments/true-usd-dark.svg");
}

.payment-provider-ukash {
  background-image: url("../img/payments/ukash.svg");
}

.payment-provider-ukash-dark {
  background-image: url("../img/payments/ukash-dark.svg");
}

.payment-provider-unionpay {
  background-image: url("../img/payments/unionpay.svg");
}

.payment-provider-unionpay-dark {
  background-image: url("../img/payments/unionpay-dark.svg");
}

.payment-provider-venmo {
  background-image: url("../img/payments/venmo.svg");
}

.payment-provider-venmo-dark {
  background-image: url("../img/payments/venmo-dark.svg");
}

.payment-provider-verifone {
  background-image: url("../img/payments/verifone.svg");
}

.payment-provider-verifone-dark {
  background-image: url("../img/payments/verifone-dark.svg");
}

.payment-provider-verisign {
  background-image: url("../img/payments/verisign.svg");
}

.payment-provider-verisign-dark {
  background-image: url("../img/payments/verisign-dark.svg");
}

.payment-provider-visa {
  background-image: url("../img/payments/visa.svg");
}

.payment-provider-visa-dark {
  background-image: url("../img/payments/visa-dark.svg");
}

.payment-provider-we-chat-pay {
  background-image: url("../img/payments/we-chat-pay.svg");
}

.payment-provider-we-chat-pay-dark {
  background-image: url("../img/payments/we-chat-pay-dark.svg");
}

.payment-provider-webmoney {
  background-image: url("../img/payments/webmoney.svg");
}

.payment-provider-webmoney-dark {
  background-image: url("../img/payments/webmoney-dark.svg");
}

.payment-provider-westernunion {
  background-image: url("../img/payments/westernunion.svg");
}

.payment-provider-westernunion-dark {
  background-image: url("../img/payments/westernunion-dark.svg");
}

.payment-provider-wise {
  background-image: url("../img/payments/wise.svg");
}

.payment-provider-wise-dark {
  background-image: url("../img/payments/wise-dark.svg");
}

.payment-provider-worldpay {
  background-image: url("../img/payments/worldpay.svg");
}

.payment-provider-worldpay-dark {
  background-image: url("../img/payments/worldpay-dark.svg");
}

.payment-provider-zelle {
  background-image: url("../img/payments/zelle.svg");
}

.payment-provider-zelle-dark {
  background-image: url("../img/payments/zelle-dark.svg");
}

.payment-xxs {
  height: 1rem;
}

.payment-xs {
  height: 1.25rem;
}

.payment-sm {
  height: 2rem;
}

.payment-md {
  height: 2.5rem;
}

.payment-lg {
  height: 3rem;
}

.payment-xl {
  height: 5rem;
}

.payment-2xl {
  height: 7rem;
}
